<template>
  <div class="spaceCards animationOpacity">
    <modal-upload></modal-upload>
    <div class="headerFlex">
      <div class="title">
        Cards
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 11H1C0.734784 11 0.48043 11.1054 0.292893 11.2929C0.105357 11.4804 0 11.7348 0 12V19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H8C8.26522 20 8.51957 19.8946 8.70711 19.7071C8.89464 19.5196 9 19.2652 9 19V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM7 18H2V13H7V18ZM19 0H12C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1054 0.48043 11 0.734784 11 1V8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4804 8.89464 11.7348 9 12 9H19C19.2652 9 19.5196 8.89464 19.7071 8.70711C19.8946 8.51957 20 8.26522 20 8V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM18 7H13V2H18V7ZM19 11H12C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11ZM18 18H13V13H18V18ZM8 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0ZM7 7H2V2H7V7Z"
            fill="#333333" />
        </svg>
      </div>
    </div>
    <div class="subTitle" v-if="createCard === false">Meus Cards</div>
    <div class="subTitle" v-else>Criar Card</div>
    <div class="allCards" v-if="createCard">
      <div class="spaceCreateCard">
        <div class="previewCard">
          <div :class="'notPadding col-12 col-md-' + valueCard">
            <vue-aspect-ratio ar="16:4" width="100%">
              <div v-if="midiacapacard === null" class="cardHomePrev" v-bind:style="{
                'background-image': 'url(' + midiacapacard + ') !important',
              }"></div>
              <img v-else class="cardHome" :src="midiacapacard" alt="img" loading="lazy" />
            </vue-aspect-ratio>
          </div>
        </div>
        <div class="spaceCustomCard">
          <div class="midiaCard spaceInputsCreate">
            <b-form-group label="Capa do Card" label-for="intro">
              <div class="btn-openModal" @click="showModalUploadCapaCard" v-if="midiacapacard === null">
                <p class="text">Clique e faça o Upload da Capa</p>
              </div>
              <div class="btn-openModalPreview" @click="showModalUploadCapaCard" v-else>
                <img class="imagePreviewYt animationOpacity" :src="midiacapacard" :alt="midiacapacard" />
              </div>
            </b-form-group>
          </div>
          <div class="linkCard spaceInputsCreate">
            <b-form-group label="Link do Card" label-for="name">
              <b-form-input v-model="linkCard" placeholder="Ex: https://gdigital.com.br/"></b-form-input>
            </b-form-group>
          </div>
          <div class="tamanhoCard spaceInputsCreate">
            <b-form-group label="Tamanho do Card" label-for="name">
              <b-form-input id="rangeCards" v-model="valueCard" type="range" min="2" max="12" step="1"></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="tamanhoCard spaceInputsCreate">
              <b-form-group label="Tamanho do Card" label-for="name">
                <b-form-select class="selectTypeCard" v-model="selectedType" :options="optionsType"></b-form-select>              
              </b-form-group>
            </div> -->
          <div class="tamanhoCard spaceInputsCreate" v-if="selectedType !== 'cover'">
            <b-form-group label="Posição X" label-for="name">
              <b-form-input id="rangeCards" v-model="eixoX" type="range" min="0" max="100" step="1"></b-form-input>
            </b-form-group>
          </div>
          <div class="tamanhoCard spaceInputsCreate" v-if="selectedType !== 'cover'">
            <b-form-group label="Posição Y" label-for="name">
              <b-form-input id="rangeCards" v-model="eixoY" type="range" min="0" max="100" step="1"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="footerBtn">
          <button class="btnCancela" @click="createCard = false">
            Cancelar
          </button>
          <button class="btnCreate" @click="saveCard">Salvar Card</button>
        </div>
      </div>
    </div>
    <div v-else>
      <b-row class="SpaceCardHome">
        <draggable :list="allCards" :disabled="!enabled" class="row allCards" ghost-class="ghost" @end="onEnd">
          <transition-group type="transition" name="flip-list" class="row allCards">
            <div v-for="card in allCards" :key="card.id" :data-id="card.id"
              :class="'heightCard col-12 col-md-' + card.size" :data-id-card="card.id">
              <vue-aspect-ratio ar="16:4" width="100%">
                <div class="action-box">
                  <div class="btncardact" @click="deleteCard(card.id)">
                    Deletar
                  </div>
                  <div class="btncardact" @click="editCard(card)">Editar</div>
                </div>
                <a :href="card.link" target="_blank">
                  <img class="cardHome" :src="card.media.cdn_url" alt="img" loading="lazy" />
                </a>
              </vue-aspect-ratio>
            </div>
          </transition-group>
        </draggable>
      </b-row>
    </div>
    <div class="spaceInputs" v-if="createCard === false">
      <b-form-group class="paddingNewCard">
        <div class="btn-newClass" @click="createCard = true">
          <p class="text">Adicionar mais um Card</p>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import CardsService from "@/services/resources/CardsService";
const serviceCards = CardsService.build();
import draggable from "vuedraggable";
import ModalUpload from "@/components/ModalUpload.vue";
import notify from "@/services/libs/notificacao";
import VueAspectRatio from "vue-aspect-ratio";

export default {
  components: {
    draggable,
    ModalUpload,
    "vue-aspect-ratio": VueAspectRatio,
  },
  data() {
    return {
      allCards: [],
      enabled: true,
      dragging: false,
      createCard: false,
      midiacapacard: null,
      midiacapacardid: "",
      valueCard: 6,
      linkCard: "",
      editCardTo: false,
      editCardId: "",
      eixoY: 10,
      eixoX: 10,
      selectedType: "cover",
      optionsType: [
        { value: "cover", text: "Preencher Card" },
        { value: "not-cover", text: "Posicionar Imagem" },
      ],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    editCard(card) {
      this.createCard = true;
      this.editCardTo = true;
      this.editCardId = card.id;
      this.linkCard = card.link;
      this.valueCard = card.size;
      this.midiacapacard = card.media.cdn_url;
      this.midiacapacardid = card.media.id;
    },
    saveCard() {
      var data = "";
      if (this.editCardTo) {
        data = {
          id: this.editCardId,
          size: this.valueCard,
          link: this.linkCard,
          media_id: this.midiacapacardid,
        };
        this.$root.$emit("loadOn");
        serviceCards
          .postID(data)
          .then((resp) => {
            this.$root.$emit("loadOff");
            //console.log("edit card", resp);
            notify("sucesso", "Card atualizado com Sucesso!");
            this.createCard = false;
            this.getCards();
          })
          .catch((err) => {
            //console.log(err);
            this.$root.$emit("loadOff");
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
          });
      } else {
        data = {
          size: this.valueCard,
          link: this.linkCard,
          media_id: this.midiacapacardid,
        };
        this.$root.$emit("loadOn");
        serviceCards
          .create(data)
          .then((resp) => {
            this.$root.$emit("loadOff");
            //console.log("create card", resp);
            this.createCard = false;
            notify("sucesso", "Card criado com Sucesso!");
            this.getCards();
          })
          .catch((err) => {
            //console.log(err);
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.$root.$emit("loadOff");
          });
      }
    },
    showModalUploadCapaCard() {
      this.$root.$emit("uploadcapacard");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    onEnd: function (e) {
      //console.log('event', e);

      var array = [];
      var cards = document.querySelectorAll(".heightCard");
      for (let i = 0; i < cards.length; i++) {
        const lessonId = cards[i].getAttribute("data-id");
        array.push(parseInt(lessonId));
      }
      let data = {
        id: "order",
        array,
      };
      this.$root.$emit("loadOn");
      serviceCards
        .postIDArray(data)
        .then((resp) => {
          //console.log("order cards", resp, array);
          this.getCards();
          notify("sucesso", "Ordenação Atualizada!");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Ordenar!");
          this.$root.$emit("loadOff");
        });
    },
    deleteCard(id) {
      this.$root.$emit("loadOn");
      serviceCards
        .destroy(id)
        .then((resp) => {
          //console.log("delete card", resp);
          this.getCards();
          notify("sucesso", "Card deletado com Sucesso!");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao deletar Card!");
          this.$root.$emit("loadOff");
        });
    },
    getCards() {
      this.$root.$emit("loadOn");
      serviceCards
        .search()
        .then((resp) => {
          //console.log("get cards", resp);
          this.allCards = resp.data;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
  },
  mounted() {
    this.getCards();
    this.$root.$on("newuploadcapacard", (data) => {
      this.midiacapacardid = parseInt(data.id);
      this.midiacapacard = data.url;
    });
  },
};
</script>

<style lang="scss" scoped>
.spaceCards {
  width: 100%;
  padding: 0 44px 0 48px;
  padding-top: 70px;

  .selectTypeCard {
    border: solid 1px var(--bordercolor);
    box-sizing: border-box;
    border-radius: 30px;
    height: 55px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }

  .selectTypeCard:hover {
    border: 1px solid #002363;
    font-size: 14px;
  }

  .btncardact {
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
  }

  .list-group-item {
    cursor: move;
  }

  .list-group-item i {
    cursor: pointer;
  }

  .spaceInputs {
    margin-bottom: 30px;
  }

  .paddingNewCard {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 30px;
    width: 50%;
    margin: auto;
  }

  .btn-newClass {
    background: rgba(129, 133, 142, 0.03);
    border: 2px dashed #81858e;
    box-sizing: border-box;
    border-radius: 30px;
    height: 55px;
    cursor: pointer;

    .text {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #81858e;
      display: flex;
      justify-content: center;
      margin-top: 17px;
    }
  }

  .spaceCreateCard {
    width: 100%;

    // margin: auto;
    .previewCard {
      width: 100%;
      // margin: auto;
      // justify-content: center;
      // display: flex;
    }

    .spaceCustomCard {
      // margin: auto;
      width: 50%;
    }

    // .spaceInputsCreate{
    //   width: 50%;
    // }
    .notPadding {
      padding: inherit !important;
    }

    .btn-openModalPreview {
      background: var(--maincolortrans);
      border: 2px dashed var(--maincolor);
      box-sizing: border-box;
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;

      img {
        border-radius: 3px;
        height: 100px;
      }

      .imagePreviewCapa {
        width: 100px;
        height: 100px;
        border-radius: 3px;
        overflow: hidden;
      }
    }

    .btn-openModal {
      background: var(--maincolortrans);
      border: 2px dashed var(--maincolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      cursor: pointer;

      .text {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }

    .cardHomePrev {
      height: 100%;
      width: 100%;
      background-color: #000530;
      border-radius: 30px;
      border-bottom: 0.5px solid #ededf0;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
      margin-top: 25px;
    }

    input {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
    }

    input:hover,
    input:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
  }

  .footerBtn {
    display: flex;
    margin-bottom: 40px;
    // margin: auto;
    width: 50%;
    justify-content: flex-end;

    .btnCancela {
      margin-right: 10px;
    }
  }

  .btnCancela {
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #81858e;
    width: 204px;
    height: 55px;
  }

  .heightCard:hover .action-box {
    opacity: 1;
  }

  .action-box {
    width: 100%;
    height: 100%;
    background: rgba(0, 2, 23, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0;
    transition: 0.3s;
    position: absolute;

    svg {
      margin-left: 2px;
      margin-right: 2px;
      cursor: pointer;
    }
  }

  .subTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-top: 40px;
    margin-bottom: -10px;
  }

  .allCards {
    width: 100%;
    margin-top: 40px;
  }

  .btnCreate {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    width: 204px;
    height: 55px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
  }

  .headerFlex {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      color: var(--fontcolor);
    }

    .menu {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: var(--fontcolor);
    }
  }
}

.spaceCards .allCards {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.SpaceCardHome {
  margin: 0 auto;
  padding: 40px 0;

  .heightCard {
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 30px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.cardHome {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
